import { executeCustomModuleCall } from '@/services/api/module.api';

export default {
  methods: {
    getActivitiesByLocation(
      location,
      elements,
      locationTypes,
      locationActivities
    ) {
      let types = locationTypes.filter(
        (item) => item.assembly_location === location.id
      );

      let elementActivities = elements
        .filter((element) =>
          types.some((item) => item?.type === element.element_category)
        )
        .sort((a, b) => a.order - b.order);

      return [
        ...locationActivities.filter(
          (activity) => activity.assembly_location === location.id
        ),
        ...elementActivities.map((item) => ({
          type: item.element_type,
          activity: `Placing of ${
            types.find((type) => type.type === item.element_category)
              .friendly_name
              ? types.find((type) => type.type === item.element_category)
                  .friendly_name
              : 'element'
          } (${item.element_type})`,
          position: item.element_position,
          order: parseFloat(
            `${
              types.find((type) => type.type === item.element_category).order
            }.${item?.order?.toString().padStart(2, '0')}`
          ),
          step: types.find((type) => type.type === item.element_category).step,
        })),
      ].sort((a, b) => a.order - b.order);
    },
    b64toBlob(b64Data, contentType) {
      const byteCharacters = atob(b64Data);

      let byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        let slice = byteCharacters.slice(offset, offset + 512),
          byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        let byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }
      return new Blob(byteArrays, { type: contentType });
    },
    async fetch2DDrawing(task, projectId, moduleId) {
      this.documentPreview = null;
      let drawing = task.appendixes.find(
        (appendix) => appendix.name === `2D_${moduleId}`
      );
      if (!drawing) {
        this.$store.commit('showNotification', {
          content: '2D drawing not found for this module',
          color: 'info',
        });
        return;
      }

      let file = await executeCustomModuleCall(
        projectId,
        this.moduleId,
        'fetch2DDrawing',
        {
          task: task.id,
          appendix: drawing.id,
        }
      );

      this.documentPreview =
        URL.createObjectURL(this.b64toBlob(file.file, 'application/pdf')) +
        '#view=Fit';
    },
  },
};
